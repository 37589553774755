export const headerStyles = {
  wrapper: {
    boxSizing: 'border-box',
    m: 0,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: 0,
    height: '100vh',
    minHeight: '400px',
    maxHeight: '800px',
    overflow: 'hidden',
    background: 'hsla(190, 74%, 59%, 1)',
    background: 'linear-gradient(45deg, hsla(190, 74%, 59%, 0.8) 17%, hsla(202, 100%, 50%, 1) 100%)',
  },
  container: {
    boxSizing: 'border-box',
    m: 0,
    minWidth: 0,
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    ml: [0, null, 'auto', '100px'],
    mr: [0, null, 'auto', '100px'],
    mb: '48px',
    WebkitFlex: 1,
    flex: 1,
    WebkitAlignItems: 'center',
    WebkitBoxAlign: 'center',
    MsFlexAlign: 'center',
    alignItems: 'center',
    justifyContent: ['center', null, 'space-between'],
  },
  headingBox: {
    boxSizing: 'border-box',
    letterSpacing: ['.2em', null, '.005em'],
    mb: ['40%', 0],
    minWidth: 0,
  },
  heading1: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    m: 0,
    minWidth: 0,
    lineHeight: 1.1,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'primary',
    fontSize: ['50px', null, '70px', null, '104px'],
    WebkitTextFillColor: 'transparent',
    WebkitTextStrokeWidth: '1px',
    WebkitTextStrokeColor: '#f6f6f6',
  },
  heading2: {
    boxSizing: 'border-box',
    m: 0,
    minWidth: 0,
    lineHeight: 1.1,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'primary',
    fontSize: ['48px', null, '70px', null, '104px'],
    WebkitTextFillColor: '#f6f6f6',
    WebkitTextStrokeWidth: '1px',
    WebkitTextStrokeColor: '#f6f6f6',
  },
  anglBox1: {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 0,
    width: 0,
    borderRight: '15vw solid transparent',
    borderBottom: '15vh solid #f6f6f6',
    zIndex: 2,
  },
  anglBox2: {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: 0,
    width: 0,
    borderLeft: '85vw solid transparent',
    borderBottom: '15vh solid #f6f6f6',
    zIndex: 2,
  }
};